export default class FileUtils {
    /**
     * Downloads a string as a file.
     * @param filename
     * @param text
     */
    public static downloadStringAsFile(filename: string, text: string): void {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
}