import {CardGridSingleLineNew, StandardCard} from "@laser-project/kyui";
import React, {useMemo} from "react";
import CutSettingRest from "../../../rest/CutSettingRest.ts";
import {useQuery} from "@tanstack/react-query";
import { ICutSetting } from "../../../types/models/CutSetting.ts";
import {useWebsocketHandler} from "../../../components/WebsocketHandler/useWebsocketHandler.tsx";
import CutSettingWebsocket from "../../../websocket/CutSettingWebsocket.ts";
import Statement from "../../../components/Statements/Statement.tsx";
import {SentimentVeryDissatisfied} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";

export interface CutSettingSliderProps{
    jobId: string
}

export function CutSettingSlider(props: CutSettingSliderProps) {
    const navigate = useNavigate();

    const cutSettingRest = useMemo(() => new CutSettingRest(), []);
    const cutSettingWebsocket = useMemo(() => new CutSettingWebsocket(), []);

    const {error, data, refetch} = useQuery({
        queryKey: ['cutSettings'],
        queryFn: () =>
            cutSettingRest.getByJobId(props.jobId).then(res => res.data.data as ICutSetting ),
    });

    useWebsocketHandler({webSocketUrl: cutSettingWebsocket.getAllUpdates(), refetch: refetch});

    if (error) {
        return <Statement message={error.message} icon={<SentimentVeryDissatisfied/>}/>
    }

    return (
        <CardGridSingleLineNew
            cards={data ? Object.entries(data.calibrations ?? []).map(([calibration_type, calibration_value]) => {
                    return <StandardCard
                        image="https://www.calibrate.co.uk/wp-content/uploads/2013/03/metrology.jpg"
                        onClick={() => navigate("/cut-settings/" + data._id)}
                        imageAlt=""
                        // commented out for performance reasons -> too many image requests
                        // image={imageRest.getUrlById(cutsetting.imageId)}
                        // imageAlt={"Image showing the feature " + cutsetting.description}
                        title={calibration_type}
                        chips={[{
                            title: calibration_value.toString(),
                            label: calibration_value.toString(),
                            props: {color: 'success'},
                        }]}
                        type={"model"}
                        key={props.jobId + calibration_type}
                    />
                    }) : []}
        />
)
}