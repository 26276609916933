import {useQuery} from "@tanstack/react-query";
import React, {useCallback, useMemo} from "react";
import JobRest from "../../rest/JobRest.ts";
import {Container, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {IJob, JOB_STATUS_CATEGORY} from "../../types/models/Job.ts";
import {useDropzone} from "react-dropzone";
import ImageRest from "../../rest/ImageRest.ts";
import JobWebsocket from "../../websocket/JobWebsocket.ts";
import {useWebsocketHandler} from "../../components/WebsocketHandler/useWebsocketHandler.tsx";
import CutterWebsocket from "../../websocket/CutterWebsocket.ts";
import {JobCardGrid} from "../../components/JobCardGrid/JobCardGrid.tsx";

function JobOverviewPage() {
    const navigate = useNavigate();

    const jobRest = useMemo(() => new JobRest(), []);
    const jobWebsocket = useMemo(() => new JobWebsocket(), []);
    const cutterWebsocket = useMemo(() => new CutterWebsocket(), []);
    const imageRest = useMemo(() => new ImageRest(), []);

    const useJobsByStatus = (statuses: string[]) => {
        return useQuery({
            queryKey: ['jobs', statuses],
            queryFn: () => jobRest.getByStatus(statuses).then(res => res.data as { data: IJob[] })
        });
    };

    const jobsCreated = useJobsByStatus(JOB_STATUS_CATEGORY.CREATED);
    const jobsRunning = useJobsByStatus(JOB_STATUS_CATEGORY.RUNNING);
    const jobsFinished = useJobsByStatus(JOB_STATUS_CATEGORY.FINISHED);

    const refetchJobs = useCallback(() => {
        jobsCreated.refetch();
        jobsRunning.refetch();
        jobsFinished.refetch();
    }, [jobsCreated, jobsRunning, jobsFinished]);

    // It is intended, that messages received by the cutterWebsocket trigger a refetch of the jobs.
    // This is because the cutter object is populated in the job object, so by refetching the jobs,
    // the cutter object is also updated.
    useWebsocketHandler({webSocketUrl: jobWebsocket.getAllUpdates(), refetch: refetchJobs})
    useWebsocketHandler({webSocketUrl: cutterWebsocket.getAllUpdates(), refetch: refetchJobs})

    // Handling file drop
    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((file: File) => {
            console.log("Uploaded file", file)
            const reader = new FileReader()

            reader.onabort = () => console.error('file reading was aborted')
            reader.onerror = () => console.error('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr: AllowSharedBufferSource = reader.result as AllowSharedBufferSource
                if (!binaryStr) {
                    console.log("failed to read file")
                    return
                }

                if (file.type === "image/svg+xml") {
                    const data = new FormData();
                    data.append("file", file)
                    jobRest.createJobByFile(data)
                    return
                }

                const cutplanContent: string = new TextDecoder("utf-8").decode(binaryStr);
                console.log(cutplanContent)
                jobRest.createJob({
                    title: file.name, cutplan: cutplanContent, sheetPrototype: {
                        "outline": {
                            "points": [{"x": 0, "y": 0}, {"x": 594, "y": 0}, {"x": 594, "y": 420}, {"x": 0, "y": 420}]
                        }
                    }
                })
                    .then(() => refetchJobs())

            }
            reader.readAsArrayBuffer(file)
        })
    }, [jobRest, refetchJobs])

    const addCardProps = useDropzone({
        onDrop, accept: {
            'application/xml': ['.cut'], 'image/svg+xml': ['.svg']
        }
    });

    return (<Container sx={{p: 4}}>
        <Typography variant={"h3"}>Jobs</Typography>
        <JobCardGrid jobQuery={jobsCreated} title="Created"
                     jobRest={jobRest} imageRest={imageRest} navigate={navigate}
                     addCardEnabled addCardProps={addCardProps}/>
        <JobCardGrid jobQuery={jobsRunning} title="Running"
                     jobRest={jobRest} imageRest={imageRest} navigate={navigate}/>
        <JobCardGrid jobQuery={jobsFinished} title="Finished"
                     jobRest={jobRest} imageRest={imageRest} navigate={navigate}/>
    </Container>)

}

export default JobOverviewPage