import {useQuery} from "@tanstack/react-query";
import React, {ReactNode, useEffect, useMemo, useState} from "react";
import Statement from "../../components/Statements/Statement.tsx";
import {DeleteForever, SentimentVeryDissatisfied} from "@mui/icons-material";
import {Box, Container, Stack, Typography} from "@mui/material";
import {CardGridSingleLine, ChipLine, ImageHeader, StandardCard} from "@laser-project/kyui";
import {ICutSetting} from "../../types/models/CutSetting.ts";
import CutSettingRest from "../../rest/CutSettingRest.ts";
import CutSettingWebsocket from "../../websocket/CutSettingWebsocket.ts";
import {useWebsocketHandler} from "../../components/WebsocketHandler/useWebsocketHandler.tsx";
import ImageRest from "../../rest/ImageRest.ts";
import JobRest from "../../rest/JobRest.ts";
import {useParams} from "react-router-dom";

function CutSetting() {
    const {cutSettingId} = useParams();

    const cutSettingRest = useMemo(() => new CutSettingRest(), []);
    const cutSettingWebsocket = useMemo(() => new CutSettingWebsocket(), []);
    const jobRest = useMemo(() => new JobRest(), []);
    const imageRest = useMemo(() => new ImageRest(), []);

    const [imageUrl, setImageUrl] = useState("");
    // const [cutSetting, setCutSetting] = useState<ICutSetting | null>(null);

    const {isPending, error, data, refetch} = useQuery({
        queryKey: ['cut-settings', cutSettingId],
        queryFn: () =>
            cutSettingRest.getById(cutSettingId).then(res => {
                // const _cutSetting = res.data as { data: ICutSetting }
                // setCutSetting(_cutSetting.data);
                console.log("CutSetting data:", res.data)
                return res.data as { data: ICutSetting };
            })
    })

    useWebsocketHandler({webSocketUrl: cutSettingWebsocket.getAllUpdates(), refetch: refetch});

    useEffect(() => {
        if (!data) {
            return
        }
        const fetchImage = async () => {
            try {
                const jobRes = await jobRest.getById(data.data.jobId);
                const job = jobRes.data?.data;
                const image = await imageRest.getUrlById(job.imageId);
                console.log("Image URL:", image.toString());
                setImageUrl(image.toString());
            } catch (error) {
                console.error("Error fetching image:", error);
            }
        };

        fetchImage();
    }, [data, imageRest, jobRest]);

    if (error) {
        return <Statement message={error.message} icon={<SentimentVeryDissatisfied/>}/>
    }

    function generateCards() : ReactNode[] {

        if (!data){
            console.log("No data found in cutSetting", data)
            return
        }

        function gen_calibrationCard(calibration: string, value: string) {
            return <StandardCard
                key={calibration}
                image={imageUrl}
                imageAlt=""
                title={calibration}
                chips={[
                    {
                        title: 'Value',
                        label: value,
                        props: {color: 'success'}
                    },
                ]}
                // menuActions={[
                //  {
                //      label: "Delete",
                //      onClick: () => cutSettingRest.delete(cutSetting).then(() => refetch())
                //  },
                // ]}
                type={"model"}
            />
        }


        const cutSetting = data.data

        const calibration_types = {};

        for (const key in cutSetting.calibrations){
            const group = key.split(":").slice(0, -1).join(":");
            const specific = key.split(":").slice(-1)[0];

            if (!calibration_types[group]){
                calibration_types[group] = [];
            }

            calibration_types[group].push(
                gen_calibrationCard(specific, cutSetting.calibrations[key].toString() ?? "-")
            );
        }

        const card_grids = [];

        for (const key in calibration_types){
            card_grids.push(
                <Box pb={5}>
                    <Stack spacing={1}>
                        <Typography variant={"h1"}>
                            {key}
                        </Typography>
                        <CardGridSingleLine
                        isLoading={isPending}
                        cards={calibration_types[key]}
                        />
                    </Stack>
            </Box>)
        }

        return [...card_grids];
    }

    return (
        <Box sx={{width: '100%', overFlowY: ""}}>
            <Container sx={{p: 4}}>
                <Stack spacing={3}>
                    <ImageHeader
                        images={[imageUrl]}
                        isLoading={!data}
                        menuActions={[
                            {
                                label: "Delete",
                                onClick: () => cutSettingRest.delete(data?.data._id).then(() => refetch()),
                                icon: <DeleteForever/>,
                             },
                        ]}
                        actions={[]}
                    />
                    <Stack spacing={1}>
                        <Typography variant={"h1"}>
                            {data?.data._id}
                        </Typography>
                        <ChipLine
                            chips={[
                                {
                                    id: 'lastUpdated',
                                    label: "Last Updated: " + new Date(data?.data.updatedAt).toLocaleString(),
                                },
                                {
                                    id: 'CreatedAt',
                                    label: "Created: " + new Date(data?.data.updatedAt).toLocaleString(),
                                }
                            ]}
                        />
                    </Stack>
                    { generateCards() }
                </Stack>
            </Container>
        </Box>
    )
}

export default CutSetting