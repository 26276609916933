import AbstractRest from "./AbstractRest.ts";
import axios from "axios";

class ImageRest extends AbstractRest {

    constructor() {
        super("images");
    }

    getUrlById(id: string): string {
        return this.baseUrl + "/" + id;
    }

    upload(imageFile: File) {
        const formData = new FormData();
        formData.append('image', imageFile);

        return axios.post(this.baseUrl, formData);
    }


}

export default ImageRest;