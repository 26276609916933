import AbstractWebsocket from "./AbstractWebsocket.ts";

class CutSettingWebsocket extends AbstractWebsocket {

    constructor() {
        super("cut-settings")
    }

    public getAllUpdates() {
        return this.baseUrl;
    }

}

export default CutSettingWebsocket