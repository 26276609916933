import {AddCard, CardGridSingleLine, StandardCard} from "@laser-project/kyui";
import {IPopulatedJob} from "../../types/FrontendTypes.ts";
import {getJobStatusChips} from "../StatusChips/JobStatusChips.tsx";
import {ContentCopy, DeleteForever, RestartAlt, SentimentVeryDissatisfied} from "@mui/icons-material";
import Statement from "../Statements/Statement.tsx";
import Boxel from "../Icons/Boxel.tsx";
import {Typography} from "@mui/material";
import React from "react";
import JobRest from "../../rest/JobRest.ts";
import ImageRest from "../../rest/ImageRest.ts";
import {NavigateFunction} from "react-router-dom";
import {DropzoneState} from "react-dropzone";

export interface JobCardGridProps {
    jobQuery;
    jobRest: JobRest,
    imageRest: ImageRest;
    navigate: NavigateFunction,
    title?: string;
    addCardEnabled?: boolean;
    addCardProps?: DropzoneState
}

function generateJobCards(props: JobCardGridProps) {
    const {jobQuery, jobRest, imageRest, navigate, addCardEnabled, addCardProps} = props;
    const data = jobQuery.data;

    const addCard = addCardEnabled && addCardProps ? (
        <div {...addCardProps.getRootProps()} style={{cursor: "pointer"}}>
            <input {...addCardProps.getInputProps()} />
            <AddCard/>
        </div>
    ) : null;

    if (!data?.data && addCard) {
        return [addCard];
    }

    const jobCards = data?.data?.map((job: IPopulatedJob) => (
        <StandardCard
            key={job._id}
            image={imageRest.getUrlById(job.imageId)}
            imageAlt={`Rendered Model of ${job.title ?? "Untitled"}`}
            title={job.title ?? "No title given"}
            chips={getJobStatusChips(job, true)}
            onClick={() => navigate(`/job/${job._id}`)}
            type="model"
            menuActions={[
                {
                    label: "Duplicate",
                    onClick: () => jobRest.duplicate(job._id).then((newJob) => navigate("/job/" + newJob.data.data._id)),
                    icon: <ContentCopy/>,
                },
                {
                    label: "Reset",
                    onClick: () => jobRest.reset(job._id).then(() => data.refetch()),
                    icon: <RestartAlt/>,
                },
                {
                    label: "Delete",
                    onClick: () => jobRest.delete(job._id).then(() => data.refetch()),
                    icon: <DeleteForever/>,
                },
            ]}
        />
    ));

    return addCard ? [addCard, ...jobCards] : jobCards;
}


export function JobCardGrid(props: JobCardGridProps) {
    const {jobQuery, title} = props;
    const data = jobQuery.data;

    let content = (
        <CardGridSingleLine
            isLoading={data?.isLoading}
            cards={generateJobCards(props)}
            renderEmptyGrid={() => <Statement message="No jobs found" icon={<Boxel/>}/>}
        />
    );

    if (data?.error) {
        content = <Statement message={data?.error.message ?? "An error occurred"}
                             icon={<SentimentVeryDissatisfied/>}/>;
    }

    return (
        <>
            {title && <Typography variant="h4" sx={{mt: 3, mb: 1}}>{title}</Typography>}
            {content}
        </>
    );
}