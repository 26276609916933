import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {IPopulatedCutter, IPopulatedJob} from "../../../types/FrontendTypes.ts";
import {
    CUTTER_STATE,
    CUTTER_STATE_LABEL,
    CUTTER_STATUS,
    CUTTER_STATUS_LABEL,
    ICutter
} from "../../../types/models/Cutter.ts";
import {Avatar, DialogContent, IconButton, ListItemAvatar} from "@mui/material";
import Boxel from '../../../components/Icons/Boxel.tsx';
import ImageRest from '../../../rest/ImageRest.ts';
import {Close, Launch} from "@mui/icons-material";

export interface CutterSelectionDialogProps {
    open: boolean;
    selectedCutter: string;
    handleClose: (value: string) => void;
    job: IPopulatedJob;
    cutters: IPopulatedCutter[];
    imageRest: ImageRest;
}

function getCutterSecondaryText(cutter: ICutter) {
    const status = CUTTER_STATUS_LABEL[cutter.status]
    if (cutter.status === CUTTER_STATUS.ONLINE) {
        const state = CUTTER_STATE_LABEL[cutter.state.isCutterBusy ? CUTTER_STATE.BUSY : CUTTER_STATE.IDLE]
        return `${status} · ${state}`
    }
    return status;
}

export function CutterDialog(props: CutterSelectionDialogProps) {
    const {handleClose, selectedCutter, open, job, cutters, imageRest} = props;

    const onClose = () => {
        handleClose(selectedCutter);
    };
    
    const handleListItemClick = (value: string) => {
        handleClose(value);
    };

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle>Select cutter</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 12,
                    color: theme.palette.grey[500],
                })}
            >
                <Close/>
            </IconButton>
            <DialogContent dividers sx={{p: 0, width: 300}}>
                <List sx={{pt: 0}}>
                    {cutters.map((cutter) => (
                        <ListItem disablePadding
                                  key={cutter._id}
                                  secondaryAction={
                                      <IconButton edge="end" aria-label="delete" sx={{mr: -1}}
                                                  onClick={() => window.open(`/cutter/${cutter._id}`, '_blank')}>
                                          <Launch/>
                                      </IconButton>
                                  }
                        >
                            <ListItemButton
                                selected={job?.cutterId == cutter._id}
                                onClick={() => handleListItemClick(cutter._id)}>
                                <ListItemAvatar>
                                    <Avatar src={imageRest.getUrlById(cutter.imageId)} variant="rounded">
                                        <Boxel/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={cutter.name}
                                    secondary={getCutterSecondaryText(cutter)}/>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
}
