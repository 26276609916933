import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';

const Boxel = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M21,6.968L21,17.032L12,22.064L3,17.032L3,6.968L12,1.936L21,6.968ZM6.761,5.887L3.913,7.479L6.304,8.816L7.728,8.019L10.576,9.612L9.152,10.408L12,12L14.848,10.408L13.424,9.612L16.272,8.019L17.696,8.816L20.087,7.479L17.239,5.887L16.272,6.427L13.424,4.835L14.391,4.294L12,2.957L9.609,4.294L10.576,4.835L7.728,6.427L6.761,5.887ZM20.087,13.848L20.087,10.663L19.119,11.204L19.119,9.612L17.696,10.408L17.696,8.816L14.848,10.408L14.848,12L12,13.592L12,15.184L13.424,14.388L13.424,17.573L12,18.369L12,21.043L13.424,20.246L13.424,19.165L16.272,17.573L16.272,18.654L19.119,17.062L19.119,14.388L20.087,13.848ZM7.728,18.654L10.576,20.246L10.576,17.573L12,18.369L12,15.184L10.576,14.388L10.576,12.796L9.152,12L9.152,10.408L6.304,8.816L6.304,10.408L3.913,9.071L3.913,10.663L4.881,11.204L4.881,14.388L3.913,13.848L3.913,16.521L4.881,17.062L4.881,15.981L7.728,17.573L7.728,18.654Z"/>
        </SvgIcon>
    );
};

export default Boxel;