import React, {useEffect, useMemo, useState} from "react";
import JobRest from "../../rest/JobRest.ts";
import ImageRest from "../../rest/ImageRest.ts";
import {useNavigate} from "react-router-dom";
import {IFeature} from "../../types/models/Feature.ts";
import {useCutJobApi} from "./useCutJobApi";
import {uniqBy} from "lodash";
import {IGauge, IGaugeSpec} from "../../types/models/Gauge.ts";
import {enqueueSnackbar} from "notistack";
import {GaugesSlider} from "./GaugesSlider";
import {FeatureSlider} from "./FeatureSlider";
import {JOB_STATUS, JOB_STATUS_CATEGORY} from "../../types/models/Job.ts";
import {Box, Container, Skeleton, Stack, Typography} from "@mui/material";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner.tsx";
import {CardSlider} from "./CardSlider";
import {EditableText, Expandable, ImageHeader} from "@laser-project/kyui";
import Statement from "../../components/Statements/Statement.tsx";
import {
    AirlineStops,
    Clear,
    ContentCopy,
    DeleteForever,
    DoneOutlined,
    Download,
    Error,
    HourglassTop,
    InfoOutlined,
    Launch,
    QueryStats,
    Report,
    RestartAlt,
    SignalWifiConnectedNoInternet4,
    SyncAlt
} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import {renderJobStatusChips} from "../../components/StatusChips/JobStatusChips.tsx";
import {CUTTER_STATUS} from "../../types/models/Cutter.ts";
import {CutSettingSlider} from "./CutSettingSlider/CutSettingSlider.tsx";
import {ISettingsStorage, loadSettings} from "../../types/ISettingsStorage.ts";
import CutSettingWebsocket from "../../websocket/CutSettingWebsocket.ts";
import {useWebsocketHandler} from "../../components/WebsocketHandler/useWebsocketHandler.tsx";
import {IUpdateCutSettingMessage} from "../../types/WebsocketAPITypes.ts";
import CutterDialogBuilder from "./CutterDialog/CutterDialogBuilder.tsx";
import FileUtils from "../../utils/FileUtils.ts";

export function CutJob({jobId}: Readonly<{ jobId?: string }>) {
    const navigate = useNavigate();

    const [settings] = useState<ISettingsStorage>(loadSettings());

    const cutSettingWebsocket = useMemo(() => new CutSettingWebsocket(), []);
    useWebsocketHandler({
        webSocketUrl: cutSettingWebsocket.getAllUpdates(),
        messageHandler: {
            UPDATE_CUT_SETTING: (msg: IUpdateCutSettingMessage) => {
                if (settings.showCutSettingsPopupJobPage)
                    enqueueSnackbar(msg.data.calibration_type + ": " + msg.data.calibration_value, {variant: "success"});
            }
        }
    });


    // Job Interfaces
    const jobRest = useMemo(() => new JobRest(), []);
    const imageRest = useMemo(() => new ImageRest(), []);

    const [filteredFeatures, setFilteredFeatures] = React.useState<IFeature[] | null>(null);
    const [images, setImages] = useState([])
    const {
        handleUserInputSubmit,
        handleStart,
        isSubmitting,
        activeActionId,
        job,
        assignCutter,
        cutters,
        lastErrorMessage,
        isStartingJob
    } = useCutJobApi({jobId})

    const {openDialog, dialogComponent} = CutterDialogBuilder({job, assignCutter, cutters, imageRest})

    // Fetching features for job
    useEffect(() => {
        if (!jobId) {
            console.warn("Did not have jobId. Aborting")
            return;
        }
        jobRest
            .getFeaturesByJobId(jobId)
            .then(response => {
                const allFeatures = response.data.data;
                let features: IFeature[] = uniqBy(allFeatures, (feature: IFeature) => feature.groupingKey);
                const blacklistedDependencyKeys: string[] = []
                features = features.map(feature => {
                    const newFeature = {...feature};
                    const cleanedDeps: (IGauge | IGaugeSpec)[] = [];
                    newFeature.dependencies?.forEach(dep => {
                        if (!blacklistedDependencyKeys.includes(dep.groupingKey)) {
                            blacklistedDependencyKeys.push(dep.groupingKey);
                            cleanedDeps.push(dep);
                        }
                    })
                    newFeature.dependencies = cleanedDeps;
                    return newFeature;
                })

                features.forEach(
                    feature => feature.entityType = "feature"
                )

                setFilteredFeatures(features)
            })
    }, [jobId, jobRest]);

    useEffect(() => {
        setImages([imageRest.getUrlById(job?.imageId ?? "")])
    }, [imageRest, job, job?.imageId]);

    useEffect(() => {
        if (!lastErrorMessage || lastErrorMessage === "") {
            return;
        }
        enqueueSnackbar(lastErrorMessage, {
            variant: 'error',
        })
    }, [lastErrorMessage]);

    const isMakeDisabled = job?.status !== JOB_STATUS.ANALYSIS_COMPLETE ||
        job.cutter?.state.isCutterBusy ||
        job.cutter?.status === CUTTER_STATUS.OFFLINE;

    function renderGauges() {
        return <GaugesSlider filteredFeatures={filteredFeatures}/>
    }

    function renderFeatures() {
        return <FeatureSlider filteredFeatures={filteredFeatures}/>
    }

    function renderCutSettings() {
        if (job) {
            return <CutSettingSlider jobId={job._id}/>
        } else {
            return <Skeleton height={500}/>
        }
    }

    function renderJobPerStage() {
        if (!job) {
            return <Skeleton height={500}/>
        }

        const restartButtonGroup = <Box sx={{display: 'flex', gap: 2, mt: 2}}>
            <LoadingButton sx={{mt: 2}}
                           color={"secondary"}
                           onClick={() => {
                               assignCutter(job.cutterId);
                           }}
                           disabled={!job?.cutter || (JOB_STATUS_CATEGORY.RUNNING).includes(job?.status)}
                           startIcon={<QueryStats/>}
                           variant={"outlined"}>Re-analyze job</LoadingButton>
            <LoadingButton sx={{mt: 2}}
                           color={"secondary"}
                           onClick={() => jobRest.reset(job._id).then((newJob) => navigate("/job/" + newJob.data.data._id))}
                           startIcon={<RestartAlt/>}
                           variant={"outlined"}>Reset job</LoadingButton>
        </Box>;

        const selectCutterButton = <LoadingButton sx={{mt: 2}}
                                                  color={"secondary"}
                                                  onClick={openDialog}
                                                  startIcon={<SyncAlt/>}
                                                  variant={"outlined"}>{job.cutterId ? "Change cutter" : "Select cutter"}</LoadingButton>;

        switch (job?.status) {
            case JOB_STATUS.UNASSIGNED: {
                return (
                    <Statement icon={<InfoOutlined/>} message={"To analyze this job, please assign a cutter."}
                               action={selectCutterButton}/>
                )
            }
            case JOB_STATUS.ANALYSIS_PENDING: {
                if (job.cutter?.status == CUTTER_STATUS.OFFLINE) {
                    return (<Statement
                        icon={<SignalWifiConnectedNoInternet4/>}
                        message={"Cutter is offline"}
                        reason={"Select different cutter or wait until cutter is online, to analyze this job."}
                        action={selectCutterButton}
                    />);
                }
                let reason = null;
                if (job.cutter?.state.isCutterBusy && job.cutter?.state.jobId !== job._id) {
                    reason = "The selected cutter is currently busy.";
                }

                return (
                    <Stack spacing={3}>
                        <LoadingSpinner message={"Waiting for cutter to analyze this job"} reason={reason}/>
                        {
                            settings.showFeaturesPanelJobPage ? <Expandable title={"Features"}>
                                {renderFeatures()}
                            </Expandable> : <></>
                        }

                    </Stack>
                )
            }
            case JOB_STATUS.ANALYSIS_STARTED: {
                if (job.cutter?.status == CUTTER_STATUS.OFFLINE) {
                    return (<Statement
                        icon={<SignalWifiConnectedNoInternet4/>}
                        message={"Cutter is offline"}
                        reason={"Lost connection to cutter while executing this job."}
                    />);
                }
                return (
                    <Stack spacing={3}>
                        <LoadingSpinner message={"Job is being analyzed"}/>
                        {
                            settings.showFeaturesPanelJobPage ? <Expandable title={"Features"}>
                                {renderFeatures()}
                            </Expandable> : <></>
                        }

                    </Stack>
                )
            }
            case JOB_STATUS.ANALYSIS_FAILED:
                return (
                    <Box pb={10}>
                        <Statement
                            icon={<Report/>}
                            message={"Your Model could not be analyzed!"}
                            reason={job.statusReason}
                            action={restartButtonGroup}
                        />
                    </Box>
                )
            case JOB_STATUS.ANALYSIS_COMPLETE: {
                let statement = null;
                if (job.cutter?.status == CUTTER_STATUS.OFFLINE) {
                    statement = <Statement
                        icon={<SignalWifiConnectedNoInternet4/>}
                        message={"Cutter is offline"}
                        reason={"Select different cutter or wait until cutter is online, to make this job."}
                        action={selectCutterButton}
                    />;
                } else if (job.cutter?.state.isCutterBusy && job.cutter?.state.jobId !== job._id) {
                    statement = <Statement
                        icon={<HourglassTop/>}
                        message={"Cutter is busy"}
                        reason={"Select different cutter or wait until cutter is idle, to make this job."}
                        action={selectCutterButton}
                    />;
                }
                return (<>
                    {statement}
                    <Stack spacing={3}>
                        {
                            settings.showGaugePanelJobPage ?
                                <Expandable title={"Gauges"}> {renderGauges()} </Expandable> : <></>
                        }
                        {
                            settings.showFeaturesPanelJobPage ?
                                <Expandable title={"Features"}> {renderFeatures()} </Expandable> : <></>
                        }
                    </Stack>
                </>)
            }
            case JOB_STATUS.EXECUTION_PENDING: {
                if (job.cutter?.status == CUTTER_STATUS.OFFLINE) {
                    return (<Statement
                        icon={<SignalWifiConnectedNoInternet4/>}
                        message={"Cutter is offline"}
                        reason={"Lost connection to cutter while trying to execute this job."}
                    />);
                }
                return (
                    <Stack spacing={3}>
                        <LoadingSpinner message={"Waiting for cutter to execute this job"}/>
                        {settings.showFeaturesPanelJobPage ? <>{renderFeatures()}</> : <></>}
                    </Stack>
                )
            }
            case JOB_STATUS.EXECUTION_WAITING_FOR_INPUT:
            case JOB_STATUS.EXECUTION_STARTED:
            case JOB_STATUS.EXECUTION_CALIBRATING:
            case JOB_STATUS.EXECUTION_FABRICATING:
                if (job.cutter?.status == CUTTER_STATUS.OFFLINE) {
                    return (<Statement
                        icon={<SignalWifiConnectedNoInternet4/>}
                        message={"Cutter is offline"}
                        reason={"Lost connection to cutter while execute this job."}
                    />);
                }
                return (
                    <Stack spacing={3}>
                        <CardSlider
                            handleUserInputSubmit={handleUserInputSubmit}
                            elements={job.actionOrder ?? []}
                            activeElementId={activeActionId}
                            currentSubmitting={isSubmitting}
                        />
                        {
                            settings.showGaugePanelJobPage ? <Expandable title={"Gauges"}>
                                {renderGauges()}
                            </Expandable> : <></>
                        }

                        {
                            settings.showFeaturesPanelJobPage ? <Expandable title={"Features"}>
                                {renderFeatures()}
                            </Expandable> : <></>
                        }

                    </Stack>
                )
            case JOB_STATUS.EXECUTION_SUCCESS:
                return (
                    <Box pb={10}>
                        <Statement icon={<DoneOutlined/>} message={"Your Model is done!"} huge={true}/>
                    </Box>

                )
            case JOB_STATUS.EXECUTION_FAILED:
                return (
                    <Box pb={10}>
                        <Statement
                            icon={<Report/>}
                            message={"Your Model could not be produced!"}
                            reason={job.statusReason}
                            action={restartButtonGroup}
                        />
                    </Box>
                )
            case JOB_STATUS.DELETED:
                return (
                    <Box pb={10}>
                        <Statement
                            icon={<DeleteForever/>}
                            message={"This job has been deleted."}
                            reason={job.statusReason}
                        />
                    </Box>
                )
            default:
                return (
                    <Box pb={10}>
                        <Statement icon={<Error/>} message={"Unknown Job Status"}/>
                    </Box>
                )
        }
    }

    function renderImageHeaderActions() {
        const cutterName = job?.cutter?.name || "Unknown";

        const selectCutterButton = <LoadingButton
            key={"selectCutterButton"}
            color={"secondary"}
            loading={isStartingJob}
            onClick={openDialog}
            disabled={!(JOB_STATUS_CATEGORY.CREATED).includes(job?.status)}
            variant={"contained"}
        >Select Cutter</LoadingButton>;

        const makeButton = <LoadingButton
            key={"makeButton"}
            color={"secondary"}
            loading={isStartingJob}
            disabled={isMakeDisabled}
            onClick={handleStart}
            variant={"contained"}
        >Make on {cutterName}</LoadingButton>;

        if (job?.cutter) {
            return [makeButton];
        } else {
            return [selectCutterButton]
        }
    }

    if (!jobId) {
        return <Statement icon={<Clear/>} message={"No Job Specified"}/>
    }

    return [
        <Box sx={{width: '100%', overFlowY: ""}}>
            <Container sx={{p: 4}}>
                <Stack spacing={3}>
                    <ImageHeader
                        images={images}
                        isLoading={!job}
                        menuActions={[
                            ...(settings.showChangeCutter ? [
                                {
                                    label: "Change cutter",
                                    onClick: openDialog,
                                    disabled: !(JOB_STATUS_CATEGORY.CREATED).includes(job?.status),
                                    icon: <SyncAlt/>,
                                },
                                {
                                    label: "Open cutter page",
                                    onClick: () => window.open(`/cutter/${job?.cutterId}`, '_blank'),
                                    disabled: !job?.cutter,
                                    icon: <Launch/>,
                                },
                            ] : []),

                            {
                                label: "Download cutplan",
                                onClick: () => FileUtils.downloadStringAsFile(`${job.title}.cut`, job.cutplan),
                                icon: <Download/>,
                                dividerAbove: settings.showChangeCutter,
                            },
                            ...(settings.showReanalyze ? [
                                {
                                    label: "Re-analyze",
                                    onClick: () => {
                                        if (!job.cutterId) return;
                                        assignCutter(job.cutterId);
                                    },
                                    disabled: !job?.cutter || (JOB_STATUS_CATEGORY.RUNNING).includes(job?.status),
                                    icon: <QueryStats/>
                                },
                            ] : []),
                            ...(settings.showStepByStepOptionJobPage ? [
                                {
                                    label: "Step by step",
                                    onClick: () => navigate("/job/" + jobId + "/stepByStep"),
                                    icon: <AirlineStops/>,
                                    disabled: isMakeDisabled || !job?.cutter
                                }
                            ] : []),

                            {
                                label: "Duplicate",
                                onClick: () => jobRest.duplicate(job._id).then((newJob) => navigate("/job/" + newJob.data.data._id)),
                                icon: <ContentCopy/>,
                                dividerAbove: true
                            },

                            {
                                label: "Reset",
                                onClick: () => jobRest.reset(job._id).then((newJob) => navigate("/job/" + newJob.data.data._id)),
                                icon: <RestartAlt/>
                            },
                            {
                                label: "Delete",
                                onClick: () => jobRest.delete(job._id).then(() => navigate("/")),
                                icon: <DeleteForever/>,
                            },
                        ]}
                        actions={renderImageHeaderActions()}
                    />
                    <Stack spacing={1}>
                        <Typography variant={"h1"}>
                            <EditableText
                                value={job?.title}
                                placeholderText="Unnamed Job"
                                onSubmit={(title) => jobRest.update(jobId, {title: title})}
                                allowEdit
                                loading={!job}
                            />
                        </Typography>
                        {renderJobStatusChips(job)}
                    </Stack>
                    {renderJobPerStage()}
                    {
                        settings.showCutSettingsPanelJobPage ? <Expandable title="Cut Settings">
                            {renderCutSettings()}
                        </Expandable> : <></>
                    }

                </Stack>
            </Container>
        </Box>,
        dialogComponent
    ]

}