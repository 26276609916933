import React, {useEffect, useMemo, useState} from "react";
import {IPopulatedCutter} from "../../types/FrontendTypes.ts";
import CutterRest from "../../rest/CutterRest.ts";
import {useQuery} from "@tanstack/react-query";
import {useWebsocketHandler} from "../../components/WebsocketHandler/useWebsocketHandler.tsx";
import CutterWebsocket from "../../websocket/CutterWebsocket.ts";
import {Box, Container, Stack, Typography} from "@mui/material";
import {EditableText, ImageHeader} from "@laser-project/kyui";
import {renderCutterStatusChips} from "../../components/StatusChips/CutterStatusChips.tsx";
import {Image, LinkOff} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {IJob} from "../../types/models/Job.ts";
import JobRest from "../../rest/JobRest.ts";
import JobWebsocket from "../../websocket/JobWebsocket.ts";
import ImageRest from "../../rest/ImageRest.ts";
import {JobCardGrid} from "../../components/JobCardGrid/JobCardGrid.tsx";

export function Cutter({cutterId}: Readonly<{ cutterId?: string }>) {
    const navigate = useNavigate();

    const cutterRest = useMemo(() => new CutterRest(), []);
    const cutterWebsocket = useMemo(() => new CutterWebsocket(), []);

    const imageRest = useMemo(() => new ImageRest(), []);
    const jobRest = useMemo(() => new JobRest(), []);
    const jobWebsocket = useMemo(() => new JobWebsocket(), []);

    const [imageUrl, setImageUrl] = useState("")

    const cutterQuery = useQuery({
        queryKey: ['cutters', cutterId],
        queryFn: () =>
            cutterRest.getById(cutterId).then(res => {
                return res.data as { data: IPopulatedCutter };
            })
    })

    const cutter = cutterQuery.data?.data as IPopulatedCutter;

    const jobQuery = useQuery({
        queryKey: ['jobs', cutter?._id],
        queryFn: () => jobRest.getByCutter([cutter._id]).then(res => res.data as { data: IJob[] })
    });

    useWebsocketHandler({webSocketUrl: cutterWebsocket.getAllUpdates(), refetch: cutterQuery.refetch});
    useWebsocketHandler({webSocketUrl: jobWebsocket.getAllUpdates(), refetch: jobQuery.refetch});

    useEffect(() => {
        if (cutter && cutter.imageId) {
            setImageUrl(imageRest.getUrlById(cutter.imageId));
        }
    }, [cutter, imageRest]);


    function uploadImage() {
        // Create a hidden file input element
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*'; // Accept only image files
        fileInput.style.display = 'none';

        // Append to body and trigger click to open file dialog
        document.body.appendChild(fileInput);

        // Handle file selection
        fileInput.onchange = async (event) => {
            const target = event.target as HTMLInputElement;
            const file = target.files?.[0];

            if (file) {
                try {
                    const response = await imageRest.upload(file);

                    if (response.data && response.data.data) {
                        const imageId = response.data.data._id || response.data.data.id;
                        setImageUrl(imageRest.getUrlById(imageId));

                        if (cutter && cutter._id) {
                            await cutterRest.update(cutter._id, {imageId: imageId});
                            await cutterQuery.refetch();
                        }
                    }
                } catch (error) {
                    console.error("Error uploading image:", error);
                }
            }
            document.body.removeChild(fileInput);
        };

        fileInput.click();
    }

    return (
        <div>
            <Box sx={{width: '100%', overFlowY: ""}}>
                <Container sx={{p: 4}}>
                    <Stack spacing={3}>
                        <ImageHeader
                            images={[imageUrl]}
                            isLoading={cutterQuery.isLoading}
                            menuActions={[
                                {
                                    label: "Set image",
                                    onClick: () => uploadImage(),
                                    icon: <Image/>,
                                },
                                {
                                    label: "Unregister",
                                    onClick: () => cutterRest.delete(cutter._id).then(() => navigate("/cutters")),
                                    icon: <LinkOff/>
                                },

                                // ToDo: Abort button
                            ]}
                            actions={[]}
                        />
                        <Stack spacing={1}>
                            <Typography variant={"h1"}>
                                <EditableText
                                    value={cutter?.name}
                                    placeholderText="Name of Cutter"
                                    onSubmit={(name) => cutterRest.update(cutterId, {name: name})}
                                    allowEdit
                                    loading={cutterQuery.isLoading}
                                />
                            </Typography>
                            {renderCutterStatusChips(cutter)}
                        </Stack>

                        <Typography variant={"h2"}>Latest Jobs</Typography>
                        <JobCardGrid jobQuery={jobQuery} jobRest={jobRest} imageRest={imageRest} navigate={navigate}/>

                        {/*ToDo: reconfigure tutorial*/}

                    </Stack>
                </Container>
            </Box>
        </div>
    )
        ;
}

export default Cutter