import {Route, Routes} from "react-router-dom";
import JobOverviewPage from "../../pages/JobOverviewPage/JobOverviewPage.tsx";
import {StepByStepPage} from "../../pages/StepByStepPage/StepByStepPage.tsx";
import {CutJobPage} from "../../pages/CutJobPage/CutJobPage.tsx";
import CutterOverviewPage from "../../pages/CutterOverviewPage/CutterOverviewPage.tsx";
import CutSettingOverviewPage from "../../pages/CutSettingOverviewPage/CutSettingOverviewPage.tsx";
import CutSetting from "../../pages/CutSettingPage/CutSetting.tsx";
import SettingsPage from "../../pages/SettingsPage/SettingsPage.tsx";
import {CutterPage} from "../../pages/CutterPage/CutterPage.tsx";

function Router(){

    return(
            <Routes>
                <Route path={"/cutters"} element={<CutterOverviewPage/>}/>
                <Route path={"/cutter/:cutterId"} element={<CutterPage/>}/>
                <Route path={"/settings"} element={<SettingsPage/>}/>
                <Route path={"/cut-settings"} element={<CutSettingOverviewPage/>}/>
                <Route path={"/cut-settings/:cutSettingId"} element={<CutSetting/>}/>
                <Route path={"/job/:jobId/stepByStep"} element={<StepByStepPage/>}/>
                <Route path={"/job/:jobId"} element={<CutJobPage/>}/>
                <Route path={"/"} element={<JobOverviewPage/>}/>
            </Routes>
    )
}
export default Router;