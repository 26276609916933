import React, {useEffect, useState} from "react";
import {Container, FormControlLabel, Switch, Typography} from "@mui/material";
import {loadSettings, ISettingsStorage} from "../../types/ISettingsStorage.ts";

function SettingsPage() {

    const [settings, setSettings] = useState<ISettingsStorage>(loadSettings());

    useEffect(() => {
        localStorage.setItem("storage", JSON.stringify(settings));
    }, [settings]);

    return (<Container sx={{p: 4}}>
        <Typography variant={"h3"} sx={{mb: 4}}>Settings</Typography>
        <FormControlLabel control={<Switch defaultChecked={settings["showCutSettingsPanelJobPage"]}
                                           onChange={(event => setSettings({
                                               ...settings,
                                               "showCutSettingsPanelJobPage": event.target.checked
                                           }))}/>
        } label="Show Cut Settings Panel on Job Page"/>


        <br/>

        <FormControlLabel control={<Switch defaultChecked={settings["showCutSettingsPopupJobPage"]}
                                           onChange={(event => setSettings({
                                               ...settings,
                                               "showCutSettingsPopupJobPage": event.target.checked
                                           }))}/>
        } label="Show Cut Settings Popup on Job Page"/>

        <br/>

        <FormControlLabel control={<Switch defaultChecked={settings["showFeaturesPanelJobPage"]}
                                           onChange={(event => setSettings({
                                               ...settings,
                                               "showFeaturesPanelJobPage": event.target.checked
                                           }))}/>
        } label="Show Features Panel on Job Page"/>

        <br/>

        <FormControlLabel control={<Switch defaultChecked={settings["showGaugePanelJobPage"]}
                                           onChange={(event => setSettings({
                                               ...settings,
                                               "showGaugePanelJobPage": event.target.checked
                                           }))}/>
        } label="Show Gauge Panel on Job Page"/>


        <br/>

        <FormControlLabel control={<Switch defaultChecked={settings["showHeader"]}
                                           onChange={(event => {
                                               setSettings({
                                                   ...settings,
                                                   "showHeader": event.target.checked
                                               });
                                               window.location.reload();
                                           })}/>
        } label="Show Header on all pages"/>

        <br/>

        <FormControlLabel control={<Switch defaultChecked={settings["showStepByStepOptionJobPage"]}
                                           onChange={(event => setSettings({
                                               ...settings,
                                               "showStepByStepOptionJobPage": event.target.checked
                                           }))}/>
        } label="Show Step by Step on Job Page"/>

        <br/>

        <FormControlLabel control={<Switch defaultChecked={settings["showChangeCutter"]}
                                           onChange={(event => setSettings({
                                               ...settings,
                                               "showChangeCutter": event.target.checked
                                           }))}/>
        } label="Show Change Cutter on Job Page"/>

        <br/>

        <FormControlLabel control={<Switch defaultChecked={settings["showReanalyze"]}
                                           onChange={(event => setSettings({
                                               ...settings,
                                               "showReanalyze": event.target.checked
                                           }))}/>
        } label="Show Re-analyze on Job Page"/>
    </Container>)

}

export default SettingsPage