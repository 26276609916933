import AbstractRest from "./AbstractRest.ts";
import axios, {AxiosResponse} from "axios";
import {SuccessResponseData} from "../types/ReturnHelpers.ts";
import {ICutSetting, ICutSettingSpec} from "../types/models/CutSetting.ts";

class CutSettingRest extends AbstractRest {

    constructor() {
        super("cut-settings");
    }

    getById(id: string): Promise<AxiosResponse<SuccessResponseData<ICutSetting>>> {
        return axios.get(this.baseUrl + "/" + id);
    }

    getByJobId(jobId: string): Promise<AxiosResponse<SuccessResponseData<ICutSetting>>> {
        const r = axios.get(this.baseUrl + "/byJobId/" + jobId);
        return r;
    }

    create(cutSetting: ICutSettingSpec): Promise<AxiosResponse<SuccessResponseData<ICutSetting>>> {
        return axios.post(this.baseUrl, cutSetting)
    }

    delete(cutSettingId: string) {
        return axios.delete(this.baseUrl + "/" + cutSettingId)
    }

}

export default CutSettingRest;