import {useQuery} from "@tanstack/react-query";
import React, {useMemo} from "react";
import Statement from "../../components/Statements/Statement.tsx";
import {DeleteForever, SentimentVeryDissatisfied} from "@mui/icons-material";
import {Container, Typography} from "@mui/material";
import {AddCard, CardGrid, StandardCard} from "@laser-project/kyui";
import {ICutSetting, ICutSettingSpec} from "../../types/models/CutSetting.ts";
import CutSettingRest from "../../rest/CutSettingRest.ts";
import CutSettingWebsocket from "../../websocket/CutSettingWebsocket.ts";
import {useWebsocketHandler} from "../../components/WebsocketHandler/useWebsocketHandler.tsx";
import {useNavigate} from "react-router-dom";

function CutSettingOverviewPage() {
    const navigate = useNavigate();

    const cutSettingRest = useMemo(() => new CutSettingRest(), []);
    const cutSettingWebsocket = useMemo(() => new CutSettingWebsocket(), []);
    //const jobRest = useMemo(() => new JobRest(), []);
    //const imageRest = useMemo(() => new ImageRest(), []);


    const {isPending, error, data, refetch} = useQuery({
        queryKey: ['cutSettings'],
        queryFn: () =>
            cutSettingRest.get().then(res => res.data as { data: ICutSetting[] })
    })

    useWebsocketHandler({webSocketUrl: cutSettingWebsocket.getAllUpdates(), refetch: refetch});

    if (error) {
        return <Statement message={error.message} icon={<SentimentVeryDissatisfied/>}/>
    }

    function generateCards() {

        const add_card = <AddCard
            key={1}
            onClick={() => {
                cutSettingRest.create({} as ICutSettingSpec).then(() => refetch())
            }}/>

        if (!data) {
            return
        }

        const CutSettingCard = ({ cutSetting }: { cutSetting: ICutSetting }) => {
            // const [imageUrl, setImageUrl] = useState("");

            // TODO fix endless refetch error
            // useEffect(() => {
            //     const fetchImage = async () => {
            //         try {
            //             const jobRes = await jobRest.getById(cutSetting.jobId);
            //             const job = jobRes.data.data;
            //             const image = await imageRest.getUrlById(job.imageId);
            //             setImageUrl(image.toString());
            //         } catch (error) {
            //             console.error("Error fetching image:", error);
            //         }
            //     };
            //
            //     fetchImage();
            // }, [cutSetting.jobId]);

            // const fetchImage = async () => {
            //     try {
            //         const jobRes = await jobRest.getById(cutSetting.jobId);
            //         const job = jobRes.data.data;
            //         const image = await imageRest.getUrlById(job.imageId);
            //         return image.toString();
            //     } catch (error) {
            //         console.error("Error fetching image:", error);
            //     }
            // };

            return <StandardCard key={cutSetting._id}
                 // image={ fetchImage() }
                 image={""}
                 imageAlt=""
                 onClick={() => navigate("/cut-settings/" + cutSetting._id)}
                 title={new Date(cutSetting.createdAt).toLocaleDateString('de-DE', {
                     weekday: 'long',
                     year: 'numeric',
                     month: 'long',
                     day: 'numeric'
                 }) ?? "?"}
                 chips={[
                     {
                         title: 'Id',
                         label: cutSetting._id ?? "-",
                         props: {color: 'success'}
                     },
                 ]}
                 menuActions={[
                     {
                         label: "Delete",
                         onClick: () => cutSettingRest.delete(cutSetting._id).then(() => refetch()),
                         icon: <DeleteForever/>,
                     },
                 ]}
                 type={"model"}
            />;
        };


        const cutSettingCards = ({ data }: { data: ICutSetting[] }) => {
            return (
                data.map((cutSetting) => (
                    <CutSettingCard key={cutSetting._id} cutSetting={cutSetting} />
                ))
            );
        };

        return [add_card, ...cutSettingCards(data)]
    }

    return (<Container sx={{p: 4}}>
        <Typography variant={"h3"} sx={{mb: 4}}>CutSettings</Typography>
        <CardGrid
            isLoading={isPending}
            cards={generateCards()}
        />
    </Container>)

}

export default CutSettingOverviewPage