import {useQuery} from "@tanstack/react-query";
import {useMemo} from "react";
import Statement from "../../components/Statements/Statement.tsx";
import {DataArray, LinkOff, SentimentVeryDissatisfied} from "@mui/icons-material";
import {Container, Typography} from "@mui/material";
import {CardGrid, StandardCard} from "@laser-project/kyui";
import CutterRest from "../../rest/CutterRest.ts";
import {IPopulatedCutter} from "../../types/FrontendTypes.ts";
import CutterWebsocket from "../../websocket/CutterWebsocket.ts";
import {useWebsocketHandler} from "../../components/WebsocketHandler/useWebsocketHandler.tsx";
import {useNavigate} from "react-router-dom";
import {getCutterStatusChips} from "../../components/StatusChips/CutterStatusChips.tsx";
import ImageRest from "../../rest/ImageRest.ts";

function CutterOverviewPage() {
    const navigate = useNavigate();

    const cutterRest = useMemo(() => new CutterRest(), []);
    const cutterWebsocket = useMemo(() => new CutterWebsocket(), []);

    const imageRest = useMemo(() => new ImageRest(), []);

    const {isPending, error, data, refetch} = useQuery({
        queryKey: ['cutters'],
        queryFn: () =>
            cutterRest.get().then(res => res.data as { data: IPopulatedCutter[] })
    })

    useWebsocketHandler({webSocketUrl: cutterWebsocket.getAllUpdates(), refetch: refetch})

    if (error) {
        return <Statement message={error.message} icon={<SentimentVeryDissatisfied/>}/>
    }

    function generateCards() {
        if (!data) {
            return
        }
        const cutterCards = data?.data?.map((cutter: IPopulatedCutter) => (
            <StandardCard
                key={cutter._id}
                image={imageRest.getUrlById(cutter.imageId)}
                imageAlt=""
                onClick={() => navigate("/cutter/" + cutter._id)}
                title={cutter.name ?? "No title given"}
                chips={getCutterStatusChips(cutter)}
                menuActions={[
                    {
                        label: "Unregister",
                        onClick: () => cutterRest.delete(cutter._id).then(() => refetch()),
                        icon: <LinkOff/>
                    },
                ]}
                type={"model"}
            />
        ))

        return [...cutterCards]
    }

    return (<Container sx={{p: 4}}>
        <Typography variant={"h3"} sx={{mb: 4}}>Cutters</Typography>
        <CardGrid
            isLoading={isPending}
            cards={generateCards()}
            renderEmptyGrid={() => <Statement message={"No cutters found"} icon={<DataArray/>}/>}
        />
    </Container>)

}

export default CutterOverviewPage