export interface ISettingsStorage {
    showCutSettingsPanelJobPage: boolean;
    showCutSettingsPopupJobPage: boolean;
    showGaugePanelJobPage: boolean;
    showFeaturesPanelJobPage: boolean;
    showStepByStepOptionJobPage: boolean;
    showHeader: boolean;
    showChangeCutter: boolean;
    showReanalyze: boolean;
}

export function loadSettings(): ISettingsStorage {
    try {
        const stored = JSON.parse(localStorage.getItem("storage"));
        return stored ? stored : {
            showCutSettingsPanelJobPage: true,
            showCutSettingsPopupJobPage: true,
            showFeaturesPanelJobPage: true,
            showGaugePanelJobPage: true,
            showHeader: true,
            showStepByStepOptionJobPage: true,
            showChangeCutter: true,
            showReanalyze: true,
        } satisfies ISettingsStorage;

    } catch (error) {
        return {
            showCutSettingsPanelJobPage: true,
            showCutSettingsPopupJobPage: true,
            showFeaturesPanelJobPage: true,
            showGaugePanelJobPage: true,
            showHeader: true,
            showStepByStepOptionJobPage: true,
            showChangeCutter: true,
            showReanalyze: true,
        } satisfies ISettingsStorage;
    }
}
