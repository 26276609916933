import {IAction, IActionSpec} from "../../../types/models/Action.ts";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Box, Grid} from "@mui/material";
import {InputCard, StandardCard} from "@laser-project/kyui";
import {Check} from "@mui/icons-material";
import ImageRest from "../../../rest/ImageRest.ts";
import theme from "../../../components/Theme/themes/lightTheme.ts";
import {LoadingButton} from "@mui/lab";

export interface CardSliderProps {
    handleUserInputSubmit: (values: object | null) => Promise<void>;
    elements: (IAction[] | IActionSpec[] | undefined),
    activeElementId: (string | null),
    currentSubmitting?: boolean
}

export function CardSlider(props: CardSliderProps) {
    const imageRest = useMemo(() => new ImageRest(), []);
    const gridWidth = useRef(null);
    const scrollContainer = useRef(null);
    const [currentRunningIndex, setCurrentRunningIndex] = useState<number>(-1);
    const [gridWidthNumber, setGridWidthNumber] = useState<(number | null)>(null)
    const [isAtStart, setIsAtStart] = useState<boolean>(true)
    const [isAtEnd, setIsAtEnd] = useState<boolean>(false)


    useEffect(() => {

        function updateGridSize() {
            setGridWidthNumber(gridWidth?.current?.offsetWidth)
        }

        window.addEventListener('resize', updateGridSize)
        return () => window.removeEventListener("resize", updateGridSize)
    }, []);

    const handleNavigation = useCallback(
        e => {
            const window = e.currentTarget;
            setIsAtStart(window.scrollLeft === 0)
            setIsAtEnd(window.scrollLeft === window.scrollWidth - window.clientWidth)
        }, []
    );

    useEffect(() => {
        if (!scrollContainer.current) {
            return;
        }
        scrollContainer?.current?.addEventListener("scroll", handleNavigation);

        return () => {
            scrollContainer?.current?.removeEventListener("scroll", handleNavigation);
        };
    }, [handleNavigation, scrollContainer]);

    useEffect(() => {
        const currentIdx = props.elements?.findIndex(value => value._id === props.activeElementId)
        if (currentIdx) {
            setCurrentRunningIndex(currentIdx)
        }
    }, [props.activeElementId, props.elements]);


    useEffect(() => {
        if (!props.activeElementId) {
            return;
        }
        const element = document.getElementById(props.activeElementId);
        if (!element) {
            console.error("could not find element", props.activeElementId, element)
            return
        }
        // 1.4 is the offset factor, in which the check is about 2/3 in view
        const offset = element.getBoundingClientRect().width / 1.4
        const elementPosition = element.offsetLeft - element.parentElement.getBoundingClientRect().left
        const offsetPosition = elementPosition - offset
        element.parentElement.scrollTo({left: offsetPosition, top: 0, behavior: "smooth"})
    }, [props.activeElementId, props.elements]);

    function renderRenderLoading() {
        const list = []
        for (let i = 0; i < Math.floor(Math.random() * 10); i++) {
            list.push(
                <Box sx={{
                    width: "22rem",
                    flexShrink: 0
                }} pr={2} key={i}
                >
                    <StandardCard image={""} imageAlt={""} title={""} chips={[]} type={"model"} isLoading={true}/>
                </Box>
            )
        }
        return list
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function createOverlayProgress(idx: number, element: any) {

        if (idx !== currentRunningIndex) {
            return;
        }

        if (element.isLoading) {
            return {
                pending: element.isLoading && !(element.startTime && element.endTime),
                start: element.startTime,
                end: element.endTime
            }
        }
    }

    const slider = props.elements?.map((element, idx: number) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function replaceIdWithImage(obj: { imageId: string | undefined, items: any[] | undefined, image: any }) {
            if (obj.imageId) {
                console.log("xyz", obj.imageId)
                obj.image = imageRest.getUrlById(obj.imageId)
            }
            if (obj.items) {
                obj.items = obj.items.map(replaceIdWithImage)
            }
            return obj
        }

        let resolvedCard = <StandardCard
            title={element.title}
            //TODO Fix this
            type={"model"}
            image={imageRest.getUrlById(element.imageId)}
            imageAlt={element.title ?? "No title provided"}
            chips={[]}
            /*chips={[{
                title: element.category,
                props: {sx: {backgroundColor: "gauge.main"}}
            }, ...element.chips.map((chip) => {
                return {...chip, props: {sx: {backgroundColor: "#f5f5f5"}}}
            })]}*/
            overlayProgress={createOverlayProgress(idx, element)}
            blur={(element._id !== props.activeElementId)}
            overlayIcon={(idx < currentRunningIndex ? <Check/> : undefined)}
        />
        if (element.inputSpec) {
            resolvedCard =
                <InputCard
                    title={element.title}
                    chips={[...element.chips.map((chip) => {
                        return {...chip, props: {sx: {backgroundColor: "#f5f5f5"}}}
                    })]}
                    inputs={element.inputSpec.inputs.map(replaceIdWithImage) ?? []}
                    imageAlt={"User input"}
                    entityType={"model"}
                    onSubmit={props.handleUserInputSubmit}
                    image={imageRest.getUrlById(element.imageId)}
                    blur={(element._id !== props.activeElementId)}
                    isSubmitting={props.currentSubmitting && props.activeElementId === element._id}
                    disabled={(element._id !== props.activeElementId) || element.isLoading}
                    overlayIcon={(idx < currentRunningIndex ? <Check/> : undefined)}
                    overlayProgress={createOverlayProgress(idx, element)}
                    isDone={idx < currentRunningIndex && !(props.currentSubmitting && props.activeElementId === element._id)}
                    renderSubmitButton={(renderSubmitButtonProps) => {
                        return <LoadingButton
                            variant={"contained"}
                            size={"small"}
                            color={renderSubmitButtonProps.isDone ? "success" : "secondary"}
                            sx={{
                                height: "30px",
                                "&.Mui-disabled": {
                                    backgroundColor: renderSubmitButtonProps.isDone ? "success.light" : undefined,
                                    color: renderSubmitButtonProps.isDone ? "success.contrastText" : undefined
                                }

                            }}
                            disabled={renderSubmitButtonProps.isDisabled || renderSubmitButtonProps.isDone}
                            loading={renderSubmitButtonProps.isSubmitting}
                            onClick={renderSubmitButtonProps.handleClick}
                        >
                            {renderSubmitButtonProps.isDone ? <Check/> : element.inputSpec.confirmationText ?? "Submit"}
                        </LoadingButton>
                    }}
                />
        }

        return (
            <Box sx={{
                width: gridWidthNumber ?? "18rem",
                flexShrink: 0,
            }} pl={.1} key={idx + "-" + idx}
                 id={element._id}>
                {resolvedCard}
            </Box>
        )
    }) ?? renderRenderLoading()

    if ((slider.length === 0)) {
        //TODO Please fix this with a better Version
        return <Box sx={{height: 367}}></Box>
        //return <Statement icon={<DataArray/>} message={"No Actions found"}/>
    }

    return (
        <>
            <Box sx={{position: "relative"}}>
                <Box
                    sx={{
                        display: "flex",
                        overflowX: "scroll",
                        pt: 1,
                        pb: 1,
                        pr: 1,
                        "&::-webkit-scrollbar": {display: "none"},
                        gap: Number(theme.spacing(3).slice(0, -2)) + "px",
                    }}
                    ref={scrollContainer}
                >
                    {[...slider]}
                </Box>
                <Box
                    sx={{
                        height: "100%",
                        transition: "all 0.2s ease",
                        background: "linear-gradient(90deg, rgba(0,0,0,.2), rgba(0,0,0,.0) 7%)",
                        zIndex: 1000,
                        width: "100%",
                        position: "absolute",
                        pointerEvents: "none",
                        top: 0,
                        opacity: (isAtStart ? 0 : 1)
                    }}/>
                <Box
                    sx={{
                        height: "100%",
                        transition: "all 0.2s ease",
                        background: "linear-gradient(90deg, rgba(0,0,0,.0) 93%, rgba(0,0,0,.2) 100%)",
                        zIndex: 1000,
                        width: "100%",
                        position: "absolute",
                        pointerEvents: "none",
                        top: 0,
                        opacity: (isAtEnd ? 0 : 1)
                    }}/>
                {/*<Box sx={{
                    height: "100%",
                    zIndex: 1001,
                    width: "100%",
                    position: "absolute",
                    pointerEvents: "none",
                    top: 0,

                }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "100%",
                        padding: 2,
                        "& .MuiIconButton-root:hover": {background: "rgba(97,97,97,1)"},
                    }}>
                        <IconButton sx={{
                            background: "rgba(97,97,97,0.7)",
                            pointerEvents: "all"
                        }} onClick={() => {
                        }}>
                            <KeyboardArrowLeft fontSize={"large"} sx={{color: "#fff"}}/>
                        </IconButton>
                        <IconButton sx={{
                            background: "rgba(97,97,97,0.7)",
                            pointerEvents: "all"
                        }}>
                            <KeyboardArrowRight fontSize={"large"} sx={{color: "#fff"}} onClick={() => {
                            }}/>
                        </IconButton>
                    </Box>

                </Box>*/}
            </Box>
            <Grid container spacing={3}>
                <Grid item md={3} sm={6} xs={12}>
                    <Box sx={{width: "100%"}} ref={gridWidth}/>
                </Grid>
            </Grid>
        </>
    )
}
