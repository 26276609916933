import {IPopulatedCutter} from "../../types/FrontendTypes.ts";
import {ChipLine, SingleChipProps, StandardCardProps} from "@laser-project/kyui";
import {Skeleton} from "@mui/material";
import React from "react";
import {CUTTER_STATE_LABEL, CUTTER_STATUS, CUTTER_STATUS_LABEL} from "../../types/models/Cutter.ts";

export function getCutterStatusChips(cutter?: IPopulatedCutter): (StandardCardProps["chips"][0] & { id: string })[] {
    return [
        {
            id: 'Status',
            label: CUTTER_STATUS_LABEL[cutter.status],
            props: {color: cutter.status === CUTTER_STATUS.ONLINE ? 'success' : 'error'}
        },
        ...(cutter.status === CUTTER_STATUS.ONLINE ? [{
            id: 'State',
            label: cutter.state.isCutterBusy ? CUTTER_STATE_LABEL.BUSY : CUTTER_STATE_LABEL.IDLE
        }] : [])
    ];
}

export function renderCutterStatusChips(cutter?: IPopulatedCutter) {
    if (!cutter) {
        return (<Skeleton width={300} height={30}/>);
    }

    const chips = getCutterStatusChips(cutter) as unknown as SingleChipProps[];

    return (
        <ChipLine
            chips={chips}
        />
    );
}