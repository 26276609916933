import React from "react";
import {IPopulatedJob} from "../../../types/FrontendTypes.ts";
import {ICutter} from "../../../types/models/Cutter.ts";
import {CutterDialog} from "./CutterDialog.tsx";
import ImageRest from "../../../rest/ImageRest.ts";


interface CutterDialogBuilderProps {
    job: IPopulatedJob;
    assignCutter: (cutterId: string) => void;
    cutters: ICutter[];
    imageRest: ImageRest;
}

export default function CutterDialogBuilder(
    {job, assignCutter, cutters, imageRest}: CutterDialogBuilderProps,
) {
    const [open, setOpen] = React.useState(false);
    const [selectedCutter, setSelectedCutter] = React.useState('');

    const openDialog = () => {
        setOpen(true);
        setSelectedCutter(job.cutter?._id || '');
    };

    const handleClose = (value: string) => {
        setOpen(false);
        setSelectedCutter(value);
        if (value && value !== job.cutterId) {
            assignCutter(value);
        }
    }

    const dialogComponent = (
        <CutterDialog open={open} selectedCutter={selectedCutter} handleClose={handleClose}
                      job={job} cutters={cutters} imageRest={imageRest}/>)

    return {openDialog, dialogComponent};
}
