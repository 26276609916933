import {CardGridSingleLineNew, StandardCard} from "@laser-project/kyui";
import React, {useMemo} from "react";
import {IFeature} from "../../../types/models/Feature.ts";
import ImageRest from "../../../rest/ImageRest.ts";
import Statement from "../../../components/Statements/Statement.tsx";
import {DataArray} from "@mui/icons-material";

export interface GaugesSliderProps{
    filteredFeatures:(IFeature[] | null)

}

export function GaugesSlider(props: GaugesSliderProps){
    const imageRest = useMemo(() => new ImageRest(), []);

    return (
        <CardGridSingleLineNew
            renderEmptyGrid={() => <Statement message={"No gauges found"} icon={<DataArray/>}/>}
            cards={props.filteredFeatures?.flatMap(feat => feat.dependencies).map(step => {
                    if (!step) {
                        return undefined
                    }
                    return <StandardCard
                        image={imageRest.getUrlById(step.imageId)}
                        imageAlt={"Image showing the feature " + step.description}
                        title={step.description}
                        chips={step.chips}
                        type={"gauge"}
                        key={step._id}
                    />
                }
            ) ??
            [
                <StandardCard isLoading key={1} chips={[]} image={""} imageAlt={""} title={""}
                              type={"model"}/>,
                <StandardCard isLoading key={2} chips={[]} image={""} imageAlt={""} title={""}
                              type={"model"}/>,
                <StandardCard isLoading key={3} chips={[]} image={""} imageAlt={""} title={""}
                              type={"model"}/>,
                <StandardCard isLoading key={4} chips={[]} image={""} imageAlt={""} title={""}
                              type={"model"}/>
            ]
        }/>
    )
}